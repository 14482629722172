export const en = {};

export const ch = {
"Exchange": "交易所",
"Market Orders": "市价订单",
"Connect wallet": "连接钱包",
"Energy Available": "可用能量",
"24h Recovery": "24小时恢复",
"Payout": "支付",
"PAYOUT": "支付",
"Stake amount": "抵押金额",
"STAKE AMOUNT": "抵押金额",
"APY": "年化收益率",
"Energy": "能量",
"ENERGY": "能量",
"SUN/Day": "SUN/日",
"SUN/Hour": "SUN/1小时",
"SUN/6Hours": "SUN/6个小时",
"Duration": "期间",
"DURATION": "期间",
"Connect Wallet": "连接钱包",
"Automation": "自动化",
"Owner Address": "拥有者地址",
"Minimal prices": "最低价格",
"1 Hour": "1小时",
"6 Hours": "6个小时",
"1 Day": "1天",
"3 Days": "3天",
"7 Days": "7天",
"14 Days": "14天",
"Sun/Day": "SUN/日",
"Delegate resources": "委托资源",
"Reclaim resources": "回收资源",
"Authorize": "授权",
"Pause": "暂停",
"Save": "保存",
"Remove": "移除",
"Buy energy": "购买能量",
"Receiver": "接收者",
"TRX staked amount equivalent": "TRX 抵押金额等值",
"Energy amount": "能量数量",
"Price Sun/Day": "价格 SUN/日",
"Price": "价格",
"1 hour": "1小时",
"6 hours": "6个小时",
"1 day": "1天",
"3 days": "3天",
"7 days": "7天",
"14 days": "14天",
"Total amount": "总金额",
"Total Amount": "总金额",
"Buy": "购买",
"Help": "帮助",
"Subscribe to our telegram channel": "订阅我们的Telegram频道",
"to get notifications when new market orders are available.": "以获取新市场订单可用时的通知 ",
"Contact": "联系我们",
"Chinese": "中文",
"Sell energy": "出售能量",
"RECEIVING ACCOUNT": "收款账户",
"Available TRX Balance": "可用 TRX 余额",
"Bandwidth": "带宽",
"Edit": "编辑",
"Filled": "已填写",
"Transaction ID": "交易编号",
"Transaction IDs": "交易编号",
"reading": "正在读取",
"Not enough energy to enable automation": "没有足够的能量启用自动化",
"Fill partially": "出售能量",
};
export const ru = {
"Exchange": "Обмен",
"Market Orders": "Рыночные ордера",
"Connect wallet": "Подключить кошелек",
"Energy Available": "Доступная энергия",
"24h Recovery": "Доступно в течении 24ч",
"Payout": "Выплата",
"PAYOUT": "ВЫПЛАТА",
"Stake amount": "Сумма ставки",
"STAKE AMOUNT": "СУММА СТАВКИ",
"APY": "APY",
"Energy": "Энергия",
"ENERGY": "ЭНЕРГИЯ",
"SUN/Day": "SUN/день",
"SUN/Hour": "SUN/час",
"SUN/6Hours": "SUN/6часов",
"Duration": "Длительность",
"DURATION": "ДЛИТЕЛЬНОСТЬ",
"Connect Wallet": "Подключить Кошелек",
"Automation": "Автоматизация",
"Owner Address": "Адрес владельца",
"Minimal prices": "Минимальные цены",
"1 Hour": "1 Час",
"6 Hours": "6 Часов",
"1 Day": "1 День",
"3 Days": "3 Дня",
"7 Days": "7 Дней",
"14 Days": "14 Дней",
"Sun/Day": "SUN/День",
"Delegate resources": "Делегировать ресурсы",
"Reclaim resources": "Восстановить ресурсы",
"Authorize": "Авторизовать",
"Pause": "Пауза",
"Save": "Сохранить",
"Remove": "Удалить",
"Buy energy": "Купить",
"Receiver": "Получатель",
"TRX staked amount equivalent": "Эквивалент суммы ставки в TRX",
"Energy amount": "Количество энергии",
"Price Sun/Day": "Цена SUN/День",
"Price": "Цена",
"1 hour": "1 час",
"6 hours": "6 часов",
"1 day": "1 день",
"3 days": "3 дня",
"7 days": "7 дней",
"14 days": "14 дней",
"Total amount": "Общая сумма",
"Total Amount": "Общая Сумма",
"Buy": "Купить",
"Help": "Помощь",
"Subscribe to our telegram channel": "Подписывайтесь на наш телеграм канал",
"to get notifications when new market orders are available.": "для получения уведомлений при появлении нового заказа.",
"Contact": "Контакт",
"Chinese": "Китайский",
"Sell energy": "Продать энергию",
"RECEIVING ACCOUNT": "ПОЛУЧАТЕЛЬ",
"Available TRX Balance": "Доступное количество TRX",
"Bandwidth": "Bandwidth",
"Edit": "Изменить",
"Filled": "Заполнено",
"Transaction ID": "ID Транзакции",
"Transaction IDs": "ID Транзакций",
"reading": "обновление",
"Not enough energy to enable automation": "Не достаточно энергии для включения автоматизации",
"Fill partially": "Заполнить частично",
};
