import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from 'react-router-dom';
import { formatNumber } from '../../utils/helper';
import { checkAPI, registerAPI, getAddrBalance, mainchain } from '../../utils/blockchain';
import { toast } from 'react-toastify';
import {  useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';

import { makeStyles } from '@mui/styles';
import { setStore,initTronLinkWallet,t,  } from "../../utils/utils";
import { getData } from "../../store/appStoreSlice";
import { useSelector } from "react-redux";
import _ from "lodash";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontStyle: "normal",
    padding: "20px 122px 0",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    minHeight: "100vh",
    lineHeight: "20px",
    color: "#FFF",
    "@media (max-width: 767.98px)": {
      width: "auto",
      height: "auto",
      padding: 0,
      flexDirection:"column",
    },
  },
  titlebox: {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: "12px",
    textAlign: "center",
  },
  clicktitlebox: {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: "12px",
    textAlign: "center",
    cursor:"pointer",
  },
  
  item: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "14px",
    margin: "0 12px",
  },
  smitem: {
    fontWeight: "normal",
    fontSize: "9px",
    lineHeight: "14px",
    margin: "0",
    fontWeight: "400",
  },
  
  nitem: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "14px",
    margin: "0 12px",
  },

  pbox: {
    color: "#FFF",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: "12px",
  },

  line: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    margin: "6px",
  },
  
  linesp: {
    display: "flex",
    justifyContent: "space-between",

  },  
  titlefield: {
    fontSize: "12px",
    fontWeight: "400",
    textAlign: "right",
    width: "50%",
    padding: "0 14px",
    
  },
  valuefield: {
    fontSize: "14px",
    fontWeight: "700",
    textAlign: "left",
    width: "100%",

  },
  
  wselect: darkTheme => {
    return {
      color: darkTheme ? "white !important" : "black !important",
      backgroundColor: darkTheme ? "transparent" : "white",
      border: "1px solid #FFF!important",
    }
  },  
  linebutton: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(254, 44, 44, 0.31)",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "37px",
    textAlign: "center",
    margin: "8px 12px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#FFF",
  },
  disabledbutton: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(138, 123, 123, 0.34)",
    justifyContent: "center",
    alignItems: "center",

    textAlign: "center",

    fontSize: "12px",
    color: "#FFF",
    fontWeight: "400",
    width:"103px",
    cursor:"pointer",
    "@media (max-width: 767.98px)": {
      width: "100%",
    },
  },
  
  href: {
    color: "#FFF",
  },
  start_button: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(138, 123, 123, 0.34)",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "37px",
    textAlign: "center",
    fontSize: "10px",
    color: "#FFF",
    fontWeight: "400",
    marginTop: "14px!important",
    width: "100%",

    backgroundColor: "rgba(138, 123, 123, 0.34)!important",
    textTransform: "initial!important",
    "&:hover": {
      backgroundColor: "rgba(138, 123, 123, 0.34)!important",
    }
  },
  lxbox: {
    justifyContent: "center",
    display: "flex",
    flexDirection:"column",
  },
  box: {
    justifyContent: "center",
    display: "flex",
    borderRadius: "5px",
    background: "rgba(138, 123, 123, 0.34)",
    flexDirection:"column",
    padding: "24px",
  },

  container: {
    borderRadius: "10px",
    background: "rgba(138, 123, 123, 0.34)",
    margin: "12px",
    padding: "12px",
  },

  zcontainer: {
    borderRadius: "10px",
    background: "rgba(138, 123, 123, 0.6)",
    margin: "auto",
    padding: "12px",
    zIndex: "111",
    width: "400px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  qrcontainer: {
    borderRadius: "10px",
    background: "rgba(138, 123, 123, 0.6)",
    padding: "12px",
    textAlign: "center",
  },
  ln: {
    width: "100%",

  },
  right: {
    width: "100%",
  },
  progressbox: {
    width: "100%",
    textAlign: "left",
    margin: "12px",
  },
  infobox: {
    display: "flex",
    flexDirection:"row",
    height: "0px",
    overflow: "visible",
    paddingLeft: "8px",
    justifyContent: "right",
    "@media (max-width: 767.98px)": {
      height: "auto",
    },
  },
  bitem: {
    fontSize: "14px",
    lineHeight: "14px",
    margin: "0",
    fontWeight: "700",
  },
  block: {
    width: "100%",
    background: "rgba(0, 0, 0, 0.3)",
    borderRadius: "10px",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  progressbar: {
    width: "100%",
    borderRadius: "3px",
    background: "#F2F3F8",
    height: "6px",
  },
  
  textField_root: {
    marginTop: "15px!important",
    width: "100%",
  },
  textField: {
    color: "#FFF!important",
    "&::before": {
      borderBottom: "1px solid #FFF!important",
    },
    "&::after": {
      borderBottom: "1px solid rgba(138, 123, 123, 0.5)!important",
    }
  },  
  
  ordercard: {
    padding: "12px",
    margin: "4px 0",
    display: "flex",
    flexDirection: "column",
    border: "1px solid rgba(0, 0, 0, 0.10)",
    borderRadius: "5px",

    background: "rgba(138, 123, 123, 0.34)",


  },
  
  
}));

const Api = (props) => {
  const classes = useStyles();
  const navigate = useHistory();
  const { isConnected, defaultAccount, inProcess, istronlink } = useSelector(getData);
  const [apikey, setApikey] = useState(false);
  const [balance, setBalance] = useState(0);
  const { signMessage, signTransaction } = useWallet();

  const handleConnectWallet = (e) => {
    initTronLinkWallet();
  }
  
  const trytosign = async (e) => {
    if (istronlink) {
      return await signTransaction(await mainchain().toHex(e));
    } else {
      return await signMessage(e);
    }
  }
  
  const handleRegister = async (e) => {
    let apik = await registerAPI(defaultAccount, trytosign);
    setApikey(apik);
  }

  useEffect(async () => {
    if (isConnected && defaultAccount != null ) {
      let apik = await checkAPI(defaultAccount, trytosign);
      setApikey(apik);
    }
  }, [defaultAccount, isConnected])

  useEffect(() => {
    setStore({activePage: 'Api'})
  }, [])

  useEffect(async () => {
    if (apikey) {
      let newbalance = await getAddrBalance(apikey.deposit);
      console.log(`newbalance `, apikey.deposit, newbalance);
      setBalance(newbalance);
    }
  }, [apikey])

  const renderAPI = () => {
    return (
      <div className={classes.ordercard}>
        <div className={classes.linesp}>
          <div className={classes.block}>
            <div className={classes.ln}>
              <div className={classes.line}><div className={classes.titlefield}>Address: </div><div className={classes.valuefield}>{ apikey.deposit}</div></div>
              <div className={classes.line}><div className={classes.titlefield}>Balance: </div><div className={classes.valuefield}>{balance} TRX</div></div>
              <div className={classes.line}><div className={classes.titlefield}>API Key: </div><div className={classes.valuefield}>{apikey.apikey}</div></div>
              <div className={classes.line}><div className={classes.titlefield}>API Secret: </div><div className={classes.valuefield}>{apikey.apisecret}</div></div>

              
              
            
            </div>
            <div className={classes.line}><a className={classes.start_button} target='_blank' href="https://github.com/Tronenergize/TronEnergize-API">API Documentation</a></div>
          </div>
          <div className={classes.qrcontainer}>
          
            <img src={'/tronenergize/api/qr?qr=' + apikey.deposit} />
            
            <p><a className={classes.href} target='_blank' href={'https://tronscan.org/#/address/' + apikey.deposit}>{apikey.deposit}</a></p>
          </div>
          
        </div>
      </div>
    )
  };
    
  return (
    <div className={classes.root}>
      <div className={classes.right}>
        <div className={classes.container}>
          <div className={classes.box}>
            
            { 
              !isConnected 
              
              ? <div><div className={classes.clicktitlebox} onClick={handleConnectWallet}><img width="24" height="24" src="/img/connect.png"/> {t("Connect Wallet")}</div> 
                <div className={classes.nitem}>TronEnergize Public Rest API provides developers with a powerful toolset to interact programmatically with the TronEnergize energy marketplace. This API allows users to access essential market data, manage energy orders, and perform trading operations.</div>
                <div className={classes.line}><a className={classes.start_button} target='_blank' href="https://docs.tronenergize.com">API Documentation</a></div>
                
                </div>
              
              : inProcess ? <div className={classes.titlebox} ><img width="24" height="24" src="/img/connect.png"/> {t("Connecting ...")}</div> :
                !apikey 
                  ? <div><div className={classes.clicktitlebox} onClick={handleRegister}><img width="24" height="24" src="/img/qr24.png"/> {t("New API key")}</div><div className={classes.nitem}>TronEnergize Public Rest API provides developers with a powerful toolset to interact programmatically with the TronEnergize energy marketplace. This API allows users to access essential market data, manage energy orders, and perform trading operations.</div>
                    <div className={classes.line}><a className={classes.start_button} target='_blank' href="https://docs.tronenergize.com">API Documentation</a></div></div>
                  : renderAPI()
            }  


            <div className={classes.line}>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Api);
