import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from 'react-router-dom';
import { TextField, Button, MenuItem, Select } from "@mui/material";
import { formatNumber } from '../../utils/helper';
import { buyEnergy } from '../../utils/blockchain';
import Config from '../../config';
import { toast } from 'react-toastify';

import {  useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';

import { makeStyles } from '@mui/styles';
import { setStore,initTronLinkWallet,getRate,toDuration,tonormduration,useTransactions, toPriceDuration,t } from "../../utils/utils";
import { getData } from "../../store/appStoreSlice";
import { useSelector } from "react-redux";
import _ from "lodash";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontStyle: "normal",
    padding: "20px 122px 0",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    minHeight: "100vh",
    lineHeight: "20px",
    color: "#FFF",
    "@media (max-width: 767.98px)": {
      width: "auto",
      height: "auto",
      padding: 0,
      flexDirection:"column",
    },
  },
  titlebox: {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: "12px",
    textAlign: "center",
  },
  clicktitlebox: {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: "12px",
    textAlign: "center",
    cursor:"pointer",
  },
  
  item: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "14px",
    margin: "0 12px",
  },
  smitem: {
    fontWeight: "normal",
    fontSize: "9px",
    lineHeight: "14px",
    margin: "0",
    fontWeight: "400",
  },
  
  nitem: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "14px",
    margin: "0 12px",
  },

  pbox: {
    color: "#FFF",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: "12px",
  },

  line: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    margin: "6px",
    flexWrap: "wrap",
  },
  
  linesp: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },  
  titlefield: {
    fontSize: "12px",
    fontWeight: "400",
    textAlign: "left",
  },
  valuefield: {
    fontSize: "14px",
    fontWeight: "700",
    textAlign: "right",
  },
  
  wselect: darkTheme => {
    return {
      color: darkTheme ? "white !important" : "black !important",
      backgroundColor: darkTheme ? "transparent" : "white",
      border: "1px solid #FFF!important",
    }
  },  
  linebutton: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(254, 44, 44, 0.31)",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "37px",
    textAlign: "center",
    margin: "8px 12px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#FFF",
  },
  disabledbutton: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(138, 123, 123, 0.34)",
    justifyContent: "center",
    alignItems: "center",

    textAlign: "center",

    fontSize: "12px",
    color: "#FFF",
    fontWeight: "400",
    width:"103px",
    cursor:"pointer",
    "@media (max-width: 767.98px)": {
      width: "100%",
    },
  },
  
  smallbutton: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(138, 123, 123, 0.34)",
    justifyContent: "center",
    alignItems: "center",

    textAlign: "center",

    fontSize: "12px",
    color: "#FFF",
    fontWeight: "400",
    width:"77px",
    cursor:"pointer",
    "@media (max-width: 767.98px)": {
      width: "100%",
    },
  },
  start_button: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(138, 123, 123, 0.34)",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "37px",
    textAlign: "center",
    fontSize: "10px",
    color: "#FFF",
    fontWeight: "400",
    marginTop: "14px!important",
    width: "100%",

    backgroundColor: "rgba(138, 123, 123, 0.34)!important",
    textTransform: "initial!important",
    "&:hover": {
      backgroundColor: "rgba(138, 123, 123, 0.34)!important",
    }
  },
  authitem: {
    background: "rgba(138, 123, 123, 0.34)",
    border: "1px solid #ccc",
    borderRadius: "5px",
    color: "#979797",
    fontSize: "12px",
    height: "24px",
    lineHeight: "24px",
    marginBottom: "10px",
    marginRight: "10px",
    padding: "0 16px",
    width: "auto",
    cursor:"pointer",
  },
  duratitem: {
    whiteSpace: "nowrap",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "14px",
    height: "24px",
    lineHeight: "24px",
    marginTop: "16px",
    marginRight: "10px",
    padding: "0 16px",
    width: "auto",
  },
  duratitempl: {
    whiteSpace: "nowrap",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "14px",
    height: "24px",
    lineHeight: "24px",
    marginTop: "16px",
    marginRight: "10px",
    padding: "0 16px",
    width: "auto",
    cursor:"pointer",
  },
  lxbox: {
    justifyContent: "center",
    display: "flex",
    flexDirection:"column",
  },
  box: {
    justifyContent: "center",
    display: "flex",
    borderRadius: "5px",
    background: "rgba(138, 123, 123, 0.34)",
    flexDirection:"column",
    padding: "24px",
  },

  container: {
    borderRadius: "10px",
    background: "rgba(138, 123, 123, 0.34)",
    margin: "12px",
    padding: "12px",
  },

  zcontainer: {
    borderRadius: "10px",
    background: "rgba(138, 123, 123, 0.6)",
    margin: "auto",
    padding: "12px",
    zIndex: "111",
    width: "400px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  right: {
    width: "100%",
  },
  progressbox: {
    width: "100%",
    textAlign: "left",
    margin: "12px",
  },
  infobox: {
    display: "flex",
    flexDirection:"row",
    height: "0px",
    overflow: "visible",
    paddingLeft: "8px",
    justifyContent: "right",
    "@media (max-width: 767.98px)": {
      height: "auto",
    },
  },
  bitem: {
    fontSize: "14px",
    lineHeight: "14px",
    margin: "0",
    fontWeight: "700",
  },
  block: {
    margin: "4px",
  },
  progressbar: {
    width: "100%",
    borderRadius: "3px",
    background: "#F2F3F8",
    height: "6px",
  },
  
  textField_root: {
    marginTop: "15px!important",
    width: "100%",
  },
  textField: {
    color: "#FFF!important",
    "&::before": {
      borderBottom: "1px solid #FFF!important",
    },
    "&::after": {
      borderBottom: "1px solid rgba(138, 123, 123, 0.5)!important",
    }
  },  
  
  ordercard: {
    padding: "12px",
    margin: "4px 0",
    display: "flex",
    flexDirection: "column",
    border: "1px solid rgba(0, 0, 0, 0.10)",
    borderRadius: "5px",

    background: "rgba(138, 123, 123, 0.34)",


  },
  
  
}));

const Buy = (props) => {
  const classes = useStyles();
  const navigate = useHistory();

  const { isConnected, defaultAccount, accountInfo, modal, tronenergize, infoenergy, minprse } = useSelector(getData);
  const { signTransaction } = useWallet();
  const [priceMsg, setPriceMsg] = useState("");
  const [nrgMsg, setNrgMsg] = useState("");
  const [vnrgMsg, setVnrgMsg] = useState("");
  const [priceDuration, setPriceDuration] = useState("SUN/Day");
  const [pricekoef, setPricekoef] = useState(1);
  const [stakedamount, setStakedamount] = useState("");
  const [paybtn, setPaybtn] = useState("Pay Now");
  const [disabledPBT, setDisabledPBT] = useState(false);
  const { getTxlist, txlist } = useTransactions("buyer="+defaultAccount);
  const [avaiable, setAvaiable] = useState(0);

  const [values, setValues] = React.useState({
    receiver: '',
    price: minprse['d12'],
    duration: "86400",
    energyamount: 32000,
  });
  const [minprice, setMinprice] = React.useState(minprse['d3']);

  useEffect(async () => {
    console.log(values)
    if (values.energyamount < 32000)  {
      setVnrgMsg(t("minimum 32000 Energy!"));
    } else if ( vnrgMsg != "" ) { 
      setVnrgMsg("");
    }
    const rate = await getRate();
    setStakedamount( parseInt(Number(values.energyamount)/rate) );

  }, [values])

  useEffect(async () => {
    if (accountInfo.success)  {
      const rate = await getRate();
      setStakedamount( parseInt(Number(values.energyamount)/rate) );
    }

  }, [accountInfo])

  useEffect(async () => {
    if (defaultAccount)  {
      getTxlist();
    }

  }, [defaultAccount])
  
  useEffect(() => {
    if (infoenergy.limited) {

      var keys = Object.keys(infoenergy.limited.dEnergy[tonormduration(values.duration)]).map(function(item) {
        return Number(item);
      });

      var between = keys.filter(function(item) {
        return (item <= Number(values.price));
      });
      
      if (between && isFinite(Math.max( ...between ))) {
        console.log(Math.max( ...between ))
        console.log(infoenergy.limited.dEnergy[tonormduration(values.duration)][ (Math.max( ...between )).toString() ]);
        setAvaiable(infoenergy.limited.dEnergy[tonormduration(values.duration)][ (Math.max( ...between )).toString() ]);
      } else {
        console.log("zero");
        setAvaiable(0);
        
      }
    }
  }, [values,infoenergy])
  
  const handleEdit = async (item) => {
    await setStore({activeItem: item});
    if (item.buyer==defaultAccount) {
      navigate.push("/edit");
    } 
  }

  const handleChange = (prop) => async (event) => {
    var vaslue = event.target.value

    if (prop == 'duration') {
      if (Number(event.target.value) >= 86400) { 
        await setMinprice(minprse['d3']);
        checkPrice(minprse['d3']);
        setPriceDuration("SUN/Day");
      }
      if (Number(event.target.value) == 57600) { 
        await setMinprice(minprse['d12']);
        checkPrice(minprse['d12']);
        setPriceDuration("SUN/Day");
      }
      if (Number(event.target.value) == 28800) { 
        await setMinprice(minprse['d12']);
        checkPrice(minprse['d12']);
        setPriceDuration("SUN/Day");
      }
      if (Number(event.target.value) == 7200) { 
        await setMinprice(minprse['h6']);
        checkPrice(minprse['h6']);
        setPriceDuration("SUN/6Hours");
      }
      if (Number(event.target.value) == 1200) { 
        await setMinprice(minprse['h1']);
        checkPrice(minprse['h1']);
        setPriceDuration("SUN/Hour");
      }
    }

    if (prop == 'price') {
      if (Number(event.target.value) < minprice)  {
        setPriceMsg("minimum "+minprice+" "+priceDuration+"!");
      } else if (Number(event.target.value) > minprice*10)  {
        setPriceMsg("maximum "+minprice*10+" "+priceDuration+"!");
      } else if ( priceMsg != "" ) { 
        setPriceMsg("");
      }
    }

    setValues({ ...values, [prop]: vaslue });

  };
  
  const changeDurationUp = async () => {

    if (values.duration == "1200") {
      await changeDuration("7200");
    } else if (values.duration == "7200") {
      await changeDuration("28800");
    } else if (values.duration == "835200") {
      await changeDuration("863999");
    } else if (values.duration == "863999") {
      
    } else if (Number(values.duration) >= 28800) {
      await changeDuration(String(parseInt( ((Number(values.duration) / 28800)+1)*28800 )));
    }

  }
  const changeDurationDown = async () => {

    if (values.duration == "1200") {
      
    } else if (values.duration == "7200") {
      await changeDuration("1200");
    } else if (values.duration == "28800") {
      await changeDuration("7200");
    } else if (values.duration == "863999") {
      await changeDuration("835200");
    } else if (Number(values.duration) > 28800) {
      await changeDuration(String(parseInt( ((Number(values.duration) / 28800)-1)*28800 )));
    }

  }  
  const changeDuration = async (vaslue) => {
      if (Number(vaslue) >= 86400) { 
        await setMinprice(minprse['d3']);
        checkPrice(minprse['d3']);
        setPriceDuration("SUN/Day");
      }
      if (Number(vaslue) == 57600) { 
        await setMinprice(minprse['d12']);
        checkPrice(minprse['d12']);
        setPriceDuration("SUN/Day");
      }
      if (Number(vaslue) == 28800) { 
        await setMinprice(minprse['d12']);
        checkPrice(minprse['d12']);
        setPriceDuration("SUN/Day");
      }
      if (Number(vaslue) == 7200) { 
        await setMinprice(minprse['h6']);
        checkPrice(minprse['h6']);
        setPriceDuration("SUN/6Hours");
      }
      if (Number(vaslue) == 1200) { 
        await setMinprice(minprse['h1']);
        checkPrice(minprse['h1']);
        setPriceDuration("SUN/Hour");
      }
    setValues({ ...values, duration: vaslue });
  };
  
  const handleBuy = async () => {
    if (isConnected) {
      const total = parseInt((Number(values.price) * pricekoef * Number(values.duration) * Number(values.energyamount))/28800)/10**Config.defaultDecimal;
      if (Number(accountInfo.balance) >= total) {
        setStore({modal: true})
      } else {
        toast.error("Balance is not sufficient");
      }
    } else {
      initTronLinkWallet();
    }
  }
  
  const handleCancel = async () => {
      setStore({modal: false})
  }
 
  
  const handleConfirmBuy = async () => {
    setPaybtn("Sending ...")
    setDisabledPBT(true);
    const total = parseInt((Number(values.price) * pricekoef * Number(values.duration) * Number(values.energyamount))/28800);
    
    await buyEnergy(values.receiver, stakedamount, values.energyamount, values.price, values.duration, defaultAccount, total, tronenergize, signTransaction);
    setPaybtn("Pay Now")
    
    await setStore({modal: false})
    setDisabledPBT(false);
    navigate.push("/");
  }

  const handleConnectWallet = (e) => {
    initTronLinkWallet();
  }
  
  const checkPrice = (minp) => {
      if (Number(values.price) < minp)  {
        setPriceMsg("minimum "+minp+" "+priceDuration+"!");
      } else if (Number(values.price) > minp*10)  {
        setPriceMsg("maximum "+minp*10+" "+priceDuration+"!");
      } else if ( priceMsg != "" ) { 
        setPriceMsg("");
      }
  }

  useEffect(() => {
    if (isConnected && defaultAccount != null ) {
      setValues({ ...values, receiver: defaultAccount });
    }
  }, [defaultAccount, isConnected])

  useEffect(() => {
    setStore({activePage: 'Buy'})
  }, [])

  useEffect(() => {
    if (priceDuration == "SUN/Day") {
      setPricekoef(1);
    } else if (priceDuration == "SUN/6Hours") {
      setPricekoef(4);
    } else {
      setPricekoef(24);
    }
  }, [priceDuration])
  
  const handleOpen = async (item) => {
    await setStore({activeItem: item});
    navigate.push("/view");
    
  }





  const renderColumns = (columns) => {
    return (
      columns.map((item)=>{
        console.log(item)
        return (
          <div className={classes.ordercard}>
            <div className={classes.linesp}>
              <div className={classes.item}>
                <div className={classes.smitem}>{item.created}</div>
                <div className={classes.smitem}>{item.expired}</div>
              </div>
              <div className={classes.item}>
                <div><span className={classes.smitem}>{item.receiver} </span></div>
                <div className={classes.smitem}>{t("Receiver")}</div>
              </div>
              <div className={classes.item}>
                <div>{formatNumber((item.payout*0.75)/10**Config.defaultDecimal,3)} <span className={classes.smitem}>TRX</span></div>
                <div className={classes.smitem}>{t("PAYOUT")}</div>
              </div>
              <div className={classes.item}>
                {formatNumber(item.energy,0)}
                <div className={classes.smitem}>{t("ENERGY")}</div>
              </div>
              <div className={classes.item}>
                <div>{formatNumber(item.price,0)} <span className={classes.smitem}>SUN</span></div>
                <div className={classes.smitem}>{t(toPriceDuration(item.duration))}</div>
              </div>
              <div className={classes.item}>
                <div>{t(toDuration(item.duration))} <span className={classes.smitem}></span></div>
                <div className={classes.smitem}>{t("DURATION")}</div>
              </div>

              { item.active 
                ? <div className={classes.disabledbutton} onClick={()=>handleEdit(item)}>{t("Edit")}</div>
                : <div className={classes.disabledbutton} >{t("Filled")}</div>
              }
              <div className={ classes.smallbutton } onClick={()=>handleOpen(item)}>#{item.id} ▾</div>

            </div>
          </div>

          
        )
     }));
  };
  
  
  
  return (
    <div className={classes.root}>

      <div className={classes.right}>
        <div className={classes.container}>
          <div className={classes.box}>
            <div className={classes.infobox}>
              <div className={classes.block}>
                <p className={classes.smitem}>{t("Energy Available")}</p>
                <p className={classes.bitem}>{infoenergy.a}⚡</p>
              </div>
              <div className={classes.block}>
                <p className={classes.smitem}>{t("24h Recovery")}</p>
                <p className={classes.bitem}>{infoenergy.r}⚡</p>
              </div>
            </div>
            
            { 
              !isConnected 
              ? <div className={classes.clicktitlebox} onClick={handleConnectWallet}><img width="24" height="24" src="/img/connect.png"/> {t("Connect Wallet")}</div> : <></>
            }  


            <div className={classes.line}>
              <TextField 
                id="receiver" 
                label={t("Receiver")}
                type="text" 
                variant="standard"
                onChange={handleChange('receiver')}
                value={values.receiver}
                classes={{root: classes.textField_root}}
                InputLabelProps={{classes: {root: classes.textField}}}
                InputProps={{classes: {underline: classes.textField}}}
              />
            </div>
            <div className={classes.line}>
              <TextField 
                id="stakedamount" 
                label={t("TRX staked amount equivalent")}
                type="number" 
                variant="standard"
                disabled={true}
                
                value={stakedamount}
                error={nrgMsg != ""}
                helperText={nrgMsg}
                classes={{root: classes.textField_root}}
                InputLabelProps={{classes: {root: classes.textField}}}
                InputProps={{classes: {underline: classes.textField}}}
              />            
            </div>
            <div className={classes.line}>
              <TextField 
                id="energyamount" 
                label={t("Energy amount")}
                type="number" 
                variant="standard"
                error={vnrgMsg != ""}
                helperText={vnrgMsg}
                onChange={handleChange('energyamount')}
                value={values.energyamount}
                classes={{root: classes.textField_root}}
                InputLabelProps={{classes: {root: classes.textField}}}
                InputProps={{classes: {underline: classes.textField}}}
              />            
            </div>
            
            <div className={classes.line}>
              <div className={classes.authitem} onClick={()=>setValues({ ...values, energyamount: 32000 })}>32k</div>
              <div className={classes.authitem} onClick={()=>setValues({ ...values, energyamount: 100000 })}>100k</div>
              <div className={classes.authitem} onClick={()=>setValues({ ...values, energyamount: 200000 })}>200k</div>
              <div className={classes.authitem} onClick={()=>setValues({ ...values, energyamount: 300000 })}>300k</div>
              <div className={classes.authitem} onClick={()=>setValues({ ...values, energyamount: 500000 })}>500k</div>
              <div className={classes.authitem} onClick={()=>setValues({ ...values, energyamount: 1000000 })}>1m</div>
              <div className={classes.authitem} onClick={()=>setValues({ ...values, energyamount: 5000000 })}>5m</div>
              <div className={classes.authitem} onClick={()=>setValues({ ...values, energyamount: 10000000 })}>10m</div>
              <div className={classes.authitem} onClick={()=>setValues({ ...values, energyamount: 30000000 })}>30m</div>
            </div>
            
            
            <div className={classes.line}>
              <TextField 
                id="price" 
                label={t("Price") + ", "+t(priceDuration)+" (SUN = 0.000001 TRX)" }
                type="number" 
                variant="standard"
                onChange={handleChange('price')}
                inputProps={{ min: minprice, max: minprice*10 }}
                error={priceMsg != ""}
                helperText={priceMsg}
                
                value={values.price}
                classes={{root: classes.textField_root}}
                InputLabelProps={{classes: {root: classes.textField}}}
                InputProps={{classes: {underline: classes.textField}}}
              />            
<div className={classes.duratitempl} onClick={()=>changeDurationDown()}>-</div>
<div className={classes.duratitem}>{ t(toDuration(values.duration)) }</div>
<div className={classes.duratitempl} onClick={()=>changeDurationUp()}>+</div>
       
            </div>
            <div className={classes.line}>
              <div className={classes.authitem} onClick={()=>changeDuration("1200")}>{t("1 hour")}</div>
              <div className={classes.authitem} onClick={()=>changeDuration("7200")}>{t("6 hours")}</div>
              <div className={classes.authitem} onClick={()=>changeDuration("28800")}>{t("1 day")}</div>
              <div className={classes.authitem} onClick={()=>changeDuration("57600")}>{t("2 days")}</div>
              <div className={classes.authitem} onClick={()=>changeDuration("86400")}>{t("3 days")}</div>
              <div className={classes.authitem} onClick={()=>changeDuration("144000")}>{t("5 days")}</div>
              <div className={classes.authitem} onClick={()=>changeDuration("201600")}>{t("7 days")}</div>
              <div className={classes.authitem} onClick={()=>changeDuration("403200")}>{t("14 days")}</div>
              <div className={classes.authitem} onClick={()=>changeDuration("863999")}>{t("30 days")}</div>

            </div>

            <div className={classes.line}>
              <div className={classes.titlefield}>{t("Total Amount")}: </div>
              <div className={classes.valuefield}> { (((Number(values.price) * pricekoef * Number(values.duration) * Number(values.energyamount))/10**Config.defaultDecimal)/28800).toFixed(6) }TRX</div>
            </div>
            <div className={classes.line}>
              <div className={classes.titlefield}>{t("Energy Available")}: </div>
              <div className={classes.valuefield}> { avaiable }</div>
            </div>
          
            <div className={classes.line}>
                <Button
                  classes={{root: classes.start_button}}
                  variant="contained" 
                  color="primary"
                  onClick={handleBuy}
                  disabled={ ( (priceMsg != "") || (nrgMsg != "") || (vnrgMsg != "") ) }
                >
                  { 
                    isConnected ? <>{t("Buy")}</> : <>{t("Connect wallet")}</>
                  } 
                </Button>
            </div>
          </div>
          <div className={classes.lxbox}>
            { renderColumns(txlist) }
          </div>
        </div>
      </div>

      { modal && 
      <div className={classes.zcontainer}>
        <div className={classes.box}>
          <div className={classes.titlebox}>{t("Confirm Payment")}</div>
          <div className={classes.titlefield}>{t("Receiver")}</div>
          <div className={classes.valuefield}>{ values.receiver }</div>
          <div className={classes.titlefield}>{t("Energy Amount")}</div>
          <div className={classes.valuefield}>{ values.energyamount }</div>
          <div className={classes.titlefield}>{t("Duration")}</div>
          <div className={classes.valuefield}>{ t(toDuration(values.duration)) }</div>
          <div className={classes.titlefield}>{t("Price")}</div>
          <div className={classes.valuefield}>{ values.price }{t(priceDuration)}</div>
          <div className={classes.titlefield}>{t("Total Amount")}</div>
          <div className={classes.valuefield}>{ ((Number(values.price) * pricekoef * Number(values.duration) * Number(values.energyamount))/10**Config.defaultDecimal)/28800 }TRX</div>
          <div className={classes.titlefield}>{t("Energy Available")} </div>
          <div className={classes.valuefield}> { avaiable }</div>

          <Button
                classes={{root: classes.start_button}}
                variant="contained" 
                color="primary"
                onClick={handleConfirmBuy}
                disabled={ ( (priceMsg != "") || (nrgMsg != "") || (vnrgMsg != "") || disabledPBT ) }
              >
                {t(paybtn)}
          </Button>
          <Button
                classes={{root: classes.start_button}}
                variant="contained" 
                color="primary"
                onClick={handleCancel}
                disabled={ disabledPBT }
              >
                {t("Cancel")}
          </Button>
        </div>
      </div>
      }
      
    </div>
  )
}

export default withRouter(Buy);
