import React, { useState, useEffect } from "react";
import { withRouter, Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { setStore,t } from "../../utils/utils";
import { getData } from "../../store/appStoreSlice";
import { useSelector } from "react-redux";
import _ from "lodash";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontStyle: "normal",
    padding: "20px 122px 0",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    minHeight: "100vh",
    lineHeight: "20px",
    color: "#FFF",
    "@media (max-width: 767.98px)": {
      width: "auto",
      height: "auto",
      padding: 0,
      flexDirection:"column",
    },
  },
  titlebox: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "14px",
    margin: "12px",
  },
  lefttitlebox: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "14px",
    margin: "12px 0",
  },
  pbox: {
    color: "#FFF",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: "12px",
  },
  ordercard: {
    padding: "12px",
    margin: "12px",
    display: "flex",
    flexDirection: "column",
    border: "1px solid rgba(0, 0, 0, 0.10)",
    borderRadius: "5px",
    width: "280px",
  },
  line: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 0",
  },
  item: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
    fontWeight: "400",

  },
  link: {
    color: "#fff !important",
  },
  linebutton: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(254, 44, 44, 0.31)",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "37px",
    textAlign: "center",
    margin: "8px 12px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#FFF",
  },
  disabledbutton: {
    borderRadius: "5px",
    display: "flex",
    background: "rgba(138, 123, 123, 0.34)",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "37px",
    textAlign: "center",
    marginTop:  "14px",
    fontSize: "10px",
    color: "#FFF",
    fontWeight: "400",
  },
  box: {
    justifyContent: "space-between",
    flexWrap: "wrap",
    display: "flex",
  },
  left: {
    flexDirection: "column",
    width: "430px",
    display: "flex",
  },
  container: {
    borderRadius: "10px",
    background: "rgba(138, 123, 123, 0.34)",
    margin: "12px",
    padding: "12px",
  },
  smitem: {
    fontWeight: "normal",
    fontSize: "9px",
    lineHeight: "14px",
    margin: "0",
  },
  smritem: {
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "14px",
    margin: "0",
    textAlign: "right",
  },
  right: {
    width: "100%",
    height: "100vh",
  },
  menu_link: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "30px",
    color: "#FFF",
    textDecoration: "none",
    cursor: "pointer",
    padding: "12px",
    alignItems: "center",
    display: "flex",
  },
  menu_link_active: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "30px",
    color: "#FFF",
    textDecoration: "none",
    cursor: "pointer",
    padding: "12px",
    alignItems: "center",
    display: "flex",
    borderRadius: "16px",
    background: "linear-gradient(164deg, #BEAE1F 13.54%, #950404 43.23%, #951E04 68.86%, #CD9402 97.92%)",
    
  },
  menu_text: {

    padding: "0 12px",

  },

}));

const Help = (props) => {
  const classes = useStyles();

  useEffect(() => {
    setStore({activePage: 'Help'})
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.right}>
        <div className={classes.container}>
          <p className={classes.titlebox}>{t("Help")}</p>
          <p className={classes.pbox}>{t("Subscribe to our telegram channel")} <a href='https://t.me/TronEnergizeCom' className={classes.link}>@TronEnergizeCom</a> {t("to get notifications when new market orders are available.")} </p>
          
          <p className={classes.titlebox}>{t("API")}</p>
          <p className={classes.pbox}>{t("TronEnergize Public Rest API provides developers with a powerful toolset to interact programmatically with the TronEnergize energy marketplace. This API allows users to access essential market data, manage energy orders, and perform trading operations.")} </p>
          <p className={classes.pbox}><a className={classes.link} target='_blank' href="https://docs.tronenergize.com">API Documentation</a></p>
          <p className={classes.pbox}><a className={classes.link} href="/api_dashboard">API Dashboard</a></p>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Help);
